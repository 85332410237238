import { useState } from "react";
import SlopeInputs from "./Components/SlopeInputs";
import SlopeCanvas from "./Components/SlopeCanvas";
import SoilSelector from "./Components/SoilSelector";
import { useEffect } from "react";
import SoilTypes from "./Components/SoilTypes";
import { Box } from "@mui/material";
import useOrientation from "../../utils/useOrientation";

const style = {
  portrait: {
    justifyItems: "end",
  },
  landscape: {
    display: "grid",
    gridTemplateColumns: "1fr 1.5fr",
    gap: 1,
    gridTemplateRows: "auto",
    gridTemplateAreas: `"inputs calculator" "inputs calculator"`,
    justifyItems: "end",
    margin: "2rem auto",
    borderRadius: "10px",
    border: "solid #CCCCCC 1px",
    maxWidth: "1099px",
    boxShadow: "0px 8px 6px #CCCCCC",
  },
};

function Calculator() {
  const [soilType, setSoilType] = useState(0);
  const [depth, setDepth] = useState(5);
  const [width, setWidth] = useState(4);
  const [length, setLength] = useState(4);
  const [shieldHeight, setShieldHeight] = useState(0);
  const [span, setSpan] = useState(0);
  const [volume, setVolume] = useState(0);
  const [useShield, setUseShield] = useState(false);
  const orientation = useOrientation();

  useEffect(() => {
    let soil = SoilTypes[soilType];
    let d = parseFloat(depth < 0 ? 0 : depth > 20 ? 20 : depth);
    let w = parseFloat(width < 0 ? 0 : width > 20 ? 20 : width);
    const depthBack = d * soil.angle;
    setSpan(depthBack * 2 + w);
    let x = (depth * width * length) / 27;
    let y = (depth * depthBack * length) / 27;
    setVolume((x + y).toFixed(1));

    if (useShield) {
      setSpan(w);
      setVolume(Math.round(x));
    }

    //For shield use
    setShieldHeight(depth);
  }, [depth, width, length, shieldHeight, soilType, useShield]);

  return (
    <Box sx={style[orientation]}>
      <Box
        sx={{
          gridArea: "calculator",
          justifySelf: "left",
          borderLeft: orientation === "landscape" ? "9px solid #AFAFAF" : "",
        }}
      >
        <SoilSelector
          soilType={SoilTypes[soilType].name}
          setSoilType={setSoilType}
          index={soilType}
          max={SoilTypes.length - 1}
        />
        <SlopeCanvas
          depth={depth < 0 ? 0 : depth > 20 ? 20 : depth}
          width={width < 0 ? 0 : width > 20 ? 20 : width}
          soilType={SoilTypes[soilType]}
          shield={useShield}
        />
      </Box>
      <SlopeInputs
        depth={depth}
        setDepth={setDepth}
        width={width}
        setWidth={setWidth}
        length={length}
        setLength={setLength}
        slope={SoilTypes[soilType].slope}
        span={span}
        volume={volume}
        useShield={useShield}
        setUseShield={setUseShield}
      />
    </Box>
  );
}

export default Calculator;
