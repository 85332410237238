import Switch from "@mui/material/Switch";
import "./SlopeInputs.css";
import { CSSTransition } from "react-transition-group";
import { Box } from "@mui/material";
import useOrientation from "../../../utils/useOrientation";
import { useState } from "react";


function SlopeInputs({
  useShield,
  setUseShield,
  depth,
  setDepth,
  setWidth,
  width,
  length,
  setLength,
  slope,
  span,
  volume,
}) {
  const orientation = useOrientation();
  const [error, setError] = useState({
    depth: "",
    width: ""
  });

  function TableValueRow({ title, value, units }) {
    return (
      <tr>
        <td className="input-title">{title}:</td>
        <td className="td-no-input">
          <div className="input-value ">
            <span className="value-label">{value}</span>{" "}
            <span className="unit-label">{units}</span>
          </div>
        </td>
      </tr>
    );
  }

  const handleChange = (setValue, e, min, max, key) => {
    let x = e.target.value;
    if (x < min) {
      setError((err) => ({...err, [key]: `*Must be greater than ${min}`}))
    } else if (x > max) {
      setError((err) => ({...err, [key]: `*Must be less than ${max}`}))
    } else {
      setError((err) => ({...err, [key]: ""}))
    }
      setValue(x);

  };

  return (
    <Box sx={{ gridArea: "inputs" }} className="slope-inputs">
      <div
        className="slope-shield-selector"
        style={{
          borderRadius: orientation === "landscape" ? "10px 0 0 0" : "0px",
        }}
      >
        Slope
        <Switch
          checked={useShield}
          onChange={() => setUseShield(!useShield)}
          inputProps={{ "aria-label": "controlled" }}
        />
        Shield
      </div>
      <div>
        <table style={{ width: "100%" }}>
          <tbody>
            <tr className="user-input">
              <td className="input-title">Depth:</td>
              <td>
                <div className="input-value">
                  <input
                    // id={`${title}-input`}
                    type="number"
                    value={depth}
                    min={1}
                    max={20}
                    onChange={(e) => handleChange(setDepth, e, 1, 20, "depth")}
                  />
                  <span className="input-unit-label">Feet</span>
                </div>
                {error?.depth && <span id="growl">{error.depth}</span>}
              </td>
            </tr>
            <tr className="user-input">
              <td className="input-title">Width:</td>
              <td>
                <div className="input-value">
                  <input
                    type="number"
                    value={width}
                    min={1}
                    max={20}
                    onChange={(e) => handleChange(setWidth, e, 1, 20, "width")}
                  />
                  <span className="input-unit-label">Feet</span>
                </div>
                {error?.width && <span id="growl">{error.width}</span>}
              </td>
            </tr>
            <tr className="user-input">
              <td className="input-title">Length:</td>
              <td>
                <div className="input-value">
                  <input
                    // id={`${title}-input`}
                    type="number"
                    value={length}
                    min={1}
                    max={1000}
                    onChange={(e) => handleChange(setLength, e, 1, 1000)}
                  />
                  <span className="input-unit-label">Feet</span>
                </div>
              </td>
            </tr>
            <TableValueRow title={"Overall Span"} value={span} units={"Feet"} />
            <TableValueRow
              title={"Volume of Soil Removed"}
              value={volume}
              units={"Cubic Yards"}
            />
            <CSSTransition
              in={!useShield}
              classNames="fade1"
              timeout={1500}
              unmountOnExit
            >
              <tr>
                <td className="input-title">Max Allowable Slope:</td>
                <td className="td-no-input">
                  <div className="input-value ">
                    <span className="value-label">{slope}</span>{" "}
                    <span className="unit-label">Degrees</span>
                  </div>
                </td>
              </tr>
            </CSSTransition>
            {/* {!useShield && (
              <TableValueRow
                title={"Max Allowable Slope"}
                value={slope}
                units={"Degrees"}
              />
            )} */}
          </tbody>
        </table>
      </div>
    </Box>
  );
}

export default SlopeInputs;
